* {
  font-family: 'Roboto', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/fortuner');

/*........................................*/

/* Basic table styles */
table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  margin-top: 15%; /* Add margin to the top of the table */
}

/* Table header styles */
th {
  background-color: #3498db; /* Change header background color */
  color: #fff; /* Change header text color */
  text-align: left;
  padding: 12px;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}

/* Table row styles */
tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Table cell styles */
td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

/* Hover effect for rows */
tr:hover {
  background-color: #e0e0e0;
}

/* Add some spacing and a border around the table */
.table-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent odd-numbered rows from extending beyond the container */
}

/* Add padding to the table container */
.table-container table {
  margin: 0;
}

/* Customize odd-numbered rows (remove transparency) */
tr:nth-child(odd) {
  background-color: #f2f2f2; /* Change the background color for odd rows */
}

/* Add responsive styles for small screens */
@media screen and (max-width: 600px) {
  table {
    font-size: 14px;
  }
  th, td {
    padding: 8px;
  }
}



/*.......................................*/


.hlC {
  background: linear-gradient(45deg, #47549c, #7d90ff, #89b5f7, #6578e4, #919ddf, #a9b3f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.connectBtn .initialText {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.connectBtn:hover .initialText {
  opacity: 0;
  visibility: hidden;
}

.connectBtn .hoverText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.connectBtn:hover .hoverText {
  opacity: 1;
  visibility: visible;
}


.allWrap2 {
  height: max-content;
  border: 5px #438bf7 solid;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.back:hover {
  transform: scale(1.1);
}

.button-85:before {
  content: "";
  background: linear-gradient(45deg,
      #5669d3,
      #8598ff,
      #3e4ea7,
      #a6acce,
      #4644f3,
      #002bff,
      #7a00ff,
      #5697d3,
      #438bf7);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.videoMain {
  display: flex;
  /* Use flexbox to create a row layout */
  justify-content: space-between;
  /* Add space between the two videos */
  flex-flow: row nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.formMain2 {
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  background-color: rgba(0, 0, 0, 0.726);

}

.formMain-2 {
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: fit-content;
  height: fit-content;
  top: 60%;
  left: 50%;
  z-index: 1;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 100000;
  display: flex;
  flex-flow: row nowrap;
  background-color: rgb(0, 0, 0);

}


.formMain2- {
  transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;

  top: 50%;
  left: 50%;
  background-color: rgba(0, 0, 0, 0.726);
  z-index: 1;
}



.register2:hover {
  transform: scale(1.1);
}

.circle-container2 {
  position: fixed;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 0;
}

.circle-container2 .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#ffffff, rgb(243, 164, 75) 10%, rgba(153, 255, 255, 0) 56%);
  animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}


@keyframes fade-frames {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }

  50% {
    transform: scale3d(2.2, 2.2, 1);
  }

  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}

.circle-container2:nth-child(1) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-1;
  animation-duration: 29142ms;
  animation-delay: 19846ms;
}

@keyframes move-frames-1 {
  from {
    transform: translate3d(48vw, 101vh, 0);
  }

  to {
    transform: translate3d(35vw, -102vh, 0);
  }
}

.circle-container2:nth-child(1) .circle {
  animation-delay: 2519ms;
}

.circle-container2:nth-child(2) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-2;
  animation-duration: 36368ms;
  animation-delay: 19125ms;
}

@keyframes move-frames-2 {
  from {
    transform: translate3d(36vw, 107vh, 0);
  }

  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}

.circle-container2:nth-child(2) .circle {
  animation-delay: 1084ms;
}

.circle-container2:nth-child(3) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-3;
  animation-duration: 33108ms;
  animation-delay: 1936ms;
}

@keyframes move-frames-3 {
  from {
    transform: translate3d(51vw, 104vh, 0);
  }

  to {
    transform: translate3d(92vw, -124vh, 0);
  }
}

.circle-container2:nth-child(3) .circle {
  animation-delay: 1406ms;
}

.circle-container2:nth-child(4) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-4;
  animation-duration: 28877ms;
  animation-delay: 30762ms;
}

@keyframes move-frames-4 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }

  to {
    transform: translate3d(57vw, -113vh, 0);
  }
}

.circle-container2:nth-child(4) .circle {
  animation-delay: 2955ms;
}

.circle-container2:nth-child(5) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-5;
  animation-duration: 35824ms;
  animation-delay: 31074ms;
}

@keyframes move-frames-5 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }

  to {
    transform: translate3d(31vw, -124vh, 0);
  }
}

.circle-container2:nth-child(5) .circle {
  animation-delay: 1469ms;
}

.circle-container2:nth-child(6) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-6;
  animation-duration: 32429ms;
  animation-delay: 35335ms;
}

@keyframes move-frames-6 {
  from {
    transform: translate3d(31vw, 101vh, 0);
  }

  to {
    transform: translate3d(86vw, -116vh, 0);
  }
}

.circle-container2:nth-child(6) .circle {
  animation-delay: 653ms;
}

.circle-container2:nth-child(7) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-7;
  animation-duration: 31901ms;
  animation-delay: 31819ms;
}

@keyframes move-frames-7 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }

  to {
    transform: translate3d(43vw, -110vh, 0);
  }
}

.circle-container2:nth-child(7) .circle {
  animation-delay: 2166ms;
}

.circle-container2:nth-child(8) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-8;
  animation-duration: 28634ms;
  animation-delay: 18091ms;
}

@keyframes move-frames-8 {
  from {
    transform: translate3d(81vw, 105vh, 0);
  }

  to {
    transform: translate3d(20vw, -119vh, 0);
  }
}

.circle-container2:nth-child(8) .circle {
  animation-delay: 1433ms;
}

.circle-container2:nth-child(9) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-9;
  animation-duration: 31744ms;
  animation-delay: 15233ms;
}

@keyframes move-frames-9 {
  from {
    transform: translate3d(80vw, 101vh, 0);
  }

  to {
    transform: translate3d(54vw, -118vh, 0);
  }
}

.circle-container2:nth-child(9) .circle {
  animation-delay: 2399ms;
}

.circle-container2:nth-child(10) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-10;
  animation-duration: 29461ms;
  animation-delay: 5651ms;
}

@keyframes move-frames-10 {
  from {
    transform: translate3d(24vw, 109vh, 0);
  }

  to {
    transform: translate3d(35vw, -130vh, 0);
  }
}

.circle-container2:nth-child(10) .circle {
  animation-delay: 1080ms;
}

.circle-container2:nth-child(11) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-11;
  animation-duration: 30609ms;
  animation-delay: 34893ms;
}

@keyframes move-frames-11 {
  from {
    transform: translate3d(16vw, 103vh, 0);
  }

  to {
    transform: translate3d(94vw, -124vh, 0);
  }
}

.circle-container2:nth-child(11) .circle {
  animation-delay: 1477ms;
}

.circle-container2:nth-child(12) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-12;
  animation-duration: 29877ms;
  animation-delay: 33535ms;
}

@keyframes move-frames-12 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }

  to {
    transform: translate3d(32vw, -110vh, 0);
  }
}

.circle-container2:nth-child(12) .circle {
  animation-delay: 1105ms;
}

.circle-container2:nth-child(13) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-13;
  animation-duration: 34198ms;
  animation-delay: 21871ms;
}

@keyframes move-frames-13 {
  from {
    transform: translate3d(59vw, 101vh, 0);
  }

  to {
    transform: translate3d(92vw, -113vh, 0);
  }
}

.circle-container2:nth-child(13) .circle {
  animation-delay: 3982ms;
}

.circle-container2:nth-child(14) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-14;
  animation-duration: 31477ms;
  animation-delay: 23360ms;
}

@keyframes move-frames-14 {
  from {
    transform: translate3d(27vw, 105vh, 0);
  }

  to {
    transform: translate3d(46vw, -125vh, 0);
  }
}

.circle-container2:nth-child(14) .circle {
  animation-delay: 1912ms;
}

.circle-container2:nth-child(15) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-15;
  animation-duration: 35088ms;
  animation-delay: 19665ms;
}

@keyframes move-frames-15 {
  from {
    transform: translate3d(64vw, 104vh, 0);
  }

  to {
    transform: translate3d(93vw, -118vh, 0);
  }
}

.circle-container2:nth-child(15) .circle {
  animation-delay: 1404ms;
}

.circle-container2:nth-child(16) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-16;
  animation-duration: 31494ms;
  animation-delay: 1568ms;
}

@keyframes move-frames-16 {
  from {
    transform: translate3d(10vw, 109vh, 0);
  }

  to {
    transform: translate3d(21vw, -136vh, 0);
  }
}

.circle-container2:nth-child(16) .circle {
  animation-delay: 3706ms;
}

.circle-container2:nth-child(17) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-17;
  animation-duration: 33208ms;
  animation-delay: 30298ms;
}

@keyframes move-frames-17 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }

  to {
    transform: translate3d(75vw, -110vh, 0);
  }
}

.circle-container2:nth-child(17) .circle {
  animation-delay: 309ms;
}

.circle-container2:nth-child(18) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-18;
  animation-duration: 30460ms;
  animation-delay: 10375ms;
}

@keyframes move-frames-18 {
  from {
    transform: translate3d(59vw, 110vh, 0);
  }

  to {
    transform: translate3d(59vw, -138vh, 0);
  }
}

.circle-container2:nth-child(18) .circle {
  animation-delay: 1454ms;
}

.circle-container2:nth-child(19) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-19;
  animation-duration: 36863ms;
  animation-delay: 13714ms;
}

@keyframes move-frames-19 {
  from {
    transform: translate3d(74vw, 102vh, 0);
  }

  to {
    transform: translate3d(89vw, -117vh, 0);
  }
}

.circle-container2:nth-child(19) .circle {
  animation-delay: 2304ms;
}

.circle-container2:nth-child(20) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-20;
  animation-duration: 31143ms;
  animation-delay: 30049ms;
}

@keyframes move-frames-20 {
  from {
    transform: translate3d(62vw, 105vh, 0);
  }

  to {
    transform: translate3d(70vw, -126vh, 0);
  }
}

.circle-container2:nth-child(20) .circle {
  animation-delay: 923ms;
}

.circle-container2:nth-child(21) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-21;
  animation-duration: 29000ms;
  animation-delay: 33203ms;
}

@keyframes move-frames-21 {
  from {
    transform: translate3d(78vw, 103vh, 0);
  }

  to {
    transform: translate3d(48vw, -131vh, 0);
  }
}

.circle-container2:nth-child(21) .circle {
  animation-delay: 2225ms;
}

.circle-container2:nth-child(22) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-22;
  animation-duration: 36652ms;
  animation-delay: 17612ms;
}

@keyframes move-frames-22 {
  from {
    transform: translate3d(80vw, 104vh, 0);
  }

  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}

.circle-container2:nth-child(22) .circle {
  animation-delay: 1317ms;
}

.circle-container2:nth-child(23) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-23;
  animation-duration: 34731ms;
  animation-delay: 22173ms;
}

@keyframes move-frames-23 {
  from {
    transform: translate3d(96vw, 109vh, 0);
  }

  to {
    transform: translate3d(88vw, -135vh, 0);
  }
}

.circle-container2:nth-child(23) .circle {
  animation-delay: 3929ms;
}

.circle-container2:nth-child(24) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-24;
  animation-duration: 32220ms;
  animation-delay: 3198ms;
}

@keyframes move-frames-24 {
  from {
    transform: translate3d(58vw, 102vh, 0);
  }

  to {
    transform: translate3d(50vw, -109vh, 0);
  }
}

.circle-container2:nth-child(24) .circle {
  animation-delay: 91ms;
}

.circle-container2:nth-child(25) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-25;
  animation-duration: 35525ms;
  animation-delay: 29589ms;
}

@keyframes move-frames-25 {
  from {
    transform: translate3d(68vw, 109vh, 0);
  }

  to {
    transform: translate3d(10vw, -115vh, 0);
  }
}

.circle-container2:nth-child(25) .circle {
  animation-delay: 2158ms;
}

.circle-container2:nth-child(26) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-26;
  animation-duration: 35875ms;
  animation-delay: 33656ms;
}

@keyframes move-frames-26 {
  from {
    transform: translate3d(44vw, 109vh, 0);
  }

  to {
    transform: translate3d(9vw, -135vh, 0);
  }
}

.circle-container2:nth-child(26) .circle {
  animation-delay: 456ms;
}

.circle-container2:nth-child(27) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-27;
  animation-duration: 34577ms;
  animation-delay: 12215ms;
}

@keyframes move-frames-27 {
  from {
    transform: translate3d(85vw, 108vh, 0);
  }

  to {
    transform: translate3d(92vw, -116vh, 0);
  }
}

.circle-container2:nth-child(27) .circle {
  animation-delay: 1184ms;
}

.circle-container2:nth-child(28) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-28;
  animation-duration: 31908ms;
  animation-delay: 26869ms;
}

@keyframes move-frames-28 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }

  to {
    transform: translate3d(24vw, -118vh, 0);
  }
}

.circle-container2:nth-child(28) .circle {
  animation-delay: 673ms;
}

.circle-container2:nth-child(29) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-29;
  animation-duration: 33853ms;
  animation-delay: 288ms;
}

@keyframes move-frames-29 {
  from {
    transform: translate3d(26vw, 110vh, 0);
  }

  to {
    transform: translate3d(50vw, -128vh, 0);
  }
}

.circle-container2:nth-child(29) .circle {
  animation-delay: 676ms;
}

.circle-container2:nth-child(30) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-30;
  animation-duration: 32724ms;
  animation-delay: 9766ms;
}

@keyframes move-frames-30 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }

  to {
    transform: translate3d(52vw, -129vh, 0);
  }
}

.circle-container2:nth-child(30) .circle {
  animation-delay: 3712ms;
}

.circle-container2:nth-child(31) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-31;
  animation-duration: 30306ms;
  animation-delay: 4355ms;
}

@keyframes move-frames-31 {
  from {
    transform: translate3d(52vw, 109vh, 0);
  }

  to {
    transform: translate3d(55vw, -131vh, 0);
  }
}

.circle-container2:nth-child(31) .circle {
  animation-delay: 1652ms;
}

.circle-container2:nth-child(32) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-32;
  animation-duration: 36466ms;
  animation-delay: 23222ms;
}

@keyframes move-frames-32 {
  from {
    transform: translate3d(48vw, 107vh, 0);
  }

  to {
    transform: translate3d(33vw, -128vh, 0);
  }
}

.circle-container2:nth-child(32) .circle {
  animation-delay: 2661ms;
}

.circle-container2:nth-child(33) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-33;
  animation-duration: 28746ms;
  animation-delay: 31176ms;
}

@keyframes move-frames-33 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }

  to {
    transform: translate3d(28vw, -125vh, 0);
  }
}

.circle-container2:nth-child(33) .circle {
  animation-delay: 1657ms;
}

.circle-container2:nth-child(34) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-34;
  animation-duration: 36366ms;
  animation-delay: 10018ms;
}

@keyframes move-frames-34 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }

  to {
    transform: translate3d(69vw, -123vh, 0);
  }
}

.circle-container2:nth-child(34) .circle {
  animation-delay: 3772ms;
}

.circle-container2:nth-child(35) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-35;
  animation-duration: 29767ms;
  animation-delay: 22107ms;
}

@keyframes move-frames-35 {
  from {
    transform: translate3d(34vw, 105vh, 0);
  }

  to {
    transform: translate3d(53vw, -126vh, 0);
  }
}

.circle-container2:nth-child(35) .circle {
  animation-delay: 3793ms;
}

.circle-container2:nth-child(36) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-36;
  animation-duration: 33419ms;
  animation-delay: 28077ms;
}

@keyframes move-frames-36 {
  from {
    transform: translate3d(29vw, 104vh, 0);
  }

  to {
    transform: translate3d(90vw, -119vh, 0);
  }
}

.circle-container2:nth-child(36) .circle {
  animation-delay: 2534ms;
}

.circle-container2:nth-child(37) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-37;
  animation-duration: 30556ms;
  animation-delay: 30570ms;
}

@keyframes move-frames-37 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }

  to {
    transform: translate3d(27vw, -133vh, 0);
  }
}

.circle-container2:nth-child(37) .circle {
  animation-delay: 323ms;
}

.circle-container2:nth-child(38) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-38;
  animation-duration: 33679ms;
  animation-delay: 27547ms;
}

@keyframes move-frames-38 {
  from {
    transform: translate3d(74vw, 108vh, 0);
  }

  to {
    transform: translate3d(20vw, -123vh, 0);
  }
}

.circle-container2:nth-child(38) .circle {
  animation-delay: 3503ms;
}

.circle-container2:nth-child(39) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-39;
  animation-duration: 36428ms;
  animation-delay: 130ms;
}

@keyframes move-frames-39 {
  from {
    transform: translate3d(92vw, 104vh, 0);
  }

  to {
    transform: translate3d(99vw, -120vh, 0);
  }
}

.circle-container2:nth-child(39) .circle {
  animation-delay: 2613ms;
}

.circle-container2:nth-child(40) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-40;
  animation-duration: 36739ms;
  animation-delay: 33514ms;
}

@keyframes move-frames-40 {
  from {
    transform: translate3d(74vw, 106vh, 0);
  }

  to {
    transform: translate3d(49vw, -132vh, 0);
  }
}

.circle-container2:nth-child(40) .circle {
  animation-delay: 2325ms;
}

.circle-container2:nth-child(41) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-41;
  animation-duration: 30196ms;
  animation-delay: 20811ms;
}

@keyframes move-frames-41 {
  from {
    transform: translate3d(45vw, 106vh, 0);
  }

  to {
    transform: translate3d(56vw, -121vh, 0);
  }
}

.circle-container2:nth-child(41) .circle {
  animation-delay: 3688ms;
}

.circle-container2:nth-child(42) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-42;
  animation-duration: 36980ms;
  animation-delay: 20720ms;
}

@keyframes move-frames-42 {
  from {
    transform: translate3d(89vw, 109vh, 0);
  }

  to {
    transform: translate3d(33vw, -123vh, 0);
  }
}

.circle-container2:nth-child(42) .circle {
  animation-delay: 744ms;
}

.circle-container2:nth-child(43) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-43;
  animation-duration: 34954ms;
  animation-delay: 28366ms;
}

@keyframes move-frames-43 {
  from {
    transform: translate3d(12vw, 106vh, 0);
  }

  to {
    transform: translate3d(47vw, -136vh, 0);
  }
}

.circle-container2:nth-child(43) .circle {
  animation-delay: 763ms;
}

.circle-container2:nth-child(44) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-44;
  animation-duration: 35359ms;
  animation-delay: 8123ms;
}

@keyframes move-frames-44 {
  from {
    transform: translate3d(31vw, 104vh, 0);
  }

  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}

.circle-container2:nth-child(44) .circle {
  animation-delay: 2923ms;
}

.circle-container2:nth-child(45) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-45;
  animation-duration: 32140ms;
  animation-delay: 3024ms;
}

@keyframes move-frames-45 {
  from {
    transform: translate3d(11vw, 110vh, 0);
  }

  to {
    transform: translate3d(89vw, -133vh, 0);
  }
}

.circle-container2:nth-child(45) .circle {
  animation-delay: 1863ms;
}

.circle-container2:nth-child(46) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-46;
  animation-duration: 28466ms;
  animation-delay: 11332ms;
}

@keyframes move-frames-46 {
  from {
    transform: translate3d(87vw, 102vh, 0);
  }

  to {
    transform: translate3d(82vw, -108vh, 0);
  }
}

.circle-container2:nth-child(46) .circle {
  animation-delay: 1494ms;
}

.circle-container2:nth-child(47) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-47;
  animation-duration: 30852ms;
  animation-delay: 26331ms;
}

@keyframes move-frames-47 {
  from {
    transform: translate3d(95vw, 101vh, 0);
  }

  to {
    transform: translate3d(97vw, -105vh, 0);
  }
}

.circle-container2:nth-child(47) .circle {
  animation-delay: 3888ms;
}

.circle-container2:nth-child(48) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-48;
  animation-duration: 32146ms;
  animation-delay: 33093ms;
}

@keyframes move-frames-48 {
  from {
    transform: translate3d(48vw, 102vh, 0);
  }

  to {
    transform: translate3d(45vw, -106vh, 0);
  }
}

.circle-container2:nth-child(48) .circle {
  animation-delay: 1095ms;
}

.circle-container2:nth-child(49) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-49;
  animation-duration: 36524ms;
  animation-delay: 5829ms;
}

@keyframes move-frames-49 {
  from {
    transform: translate3d(22vw, 102vh, 0);
  }

  to {
    transform: translate3d(51vw, -127vh, 0);
  }
}

.circle-container2:nth-child(49) .circle {
  animation-delay: 1664ms;
}

.circle-container2:nth-child(50) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-50;
  animation-duration: 32664ms;
  animation-delay: 20738ms;
}

@keyframes move-frames-50 {
  from {
    transform: translate3d(21vw, 104vh, 0);
  }

  to {
    transform: translate3d(73vw, -107vh, 0);
  }
}

.circle-container2:nth-child(50) .circle {
  animation-delay: 2185ms;
}

.circle-container2:nth-child(51) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-51;
  animation-duration: 35935ms;
  animation-delay: 34643ms;
}

@keyframes move-frames-51 {
  from {
    transform: translate3d(11vw, 105vh, 0);
  }

  to {
    transform: translate3d(55vw, -117vh, 0);
  }
}

.circle-container2:nth-child(51) .circle {
  animation-delay: 3170ms;
}

.circle-container2:nth-child(52) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-52;
  animation-duration: 35002ms;
  animation-delay: 9255ms;
}

@keyframes move-frames-52 {
  from {
    transform: translate3d(37vw, 107vh, 0);
  }

  to {
    transform: translate3d(27vw, -119vh, 0);
  }
}

.circle-container2:nth-child(52) .circle {
  animation-delay: 2869ms;
}

.circle-container2:nth-child(53) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-53;
  animation-duration: 31411ms;
  animation-delay: 5029ms;
}

@keyframes move-frames-53 {
  from {
    transform: translate3d(100vw, 109vh, 0);
  }

  to {
    transform: translate3d(3vw, -138vh, 0);
  }
}

.circle-container2:nth-child(53) .circle {
  animation-delay: 2710ms;
}

.circle-container2:nth-child(54) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-54;
  animation-duration: 34484ms;
  animation-delay: 3960ms;
}

@keyframes move-frames-54 {
  from {
    transform: translate3d(59vw, 102vh, 0);
  }

  to {
    transform: translate3d(99vw, -132vh, 0);
  }
}

.circle-container2:nth-child(54) .circle {
  animation-delay: 932ms;
}

.circle-container2:nth-child(55) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-55;
  animation-duration: 30870ms;
  animation-delay: 8766ms;
}

@keyframes move-frames-55 {
  from {
    transform: translate3d(17vw, 107vh, 0);
  }

  to {
    transform: translate3d(87vw, -137vh, 0);
  }
}

.circle-container2:nth-child(55) .circle {
  animation-delay: 2816ms;
}

.circle-container2:nth-child(56) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-56;
  animation-duration: 31465ms;
  animation-delay: 15049ms;
}

@keyframes move-frames-56 {
  from {
    transform: translate3d(11vw, 104vh, 0);
  }

  to {
    transform: translate3d(59vw, -106vh, 0);
  }
}

.circle-container2:nth-child(56) .circle {
  animation-delay: 502ms;
}

.circle-container2:nth-child(57) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-57;
  animation-duration: 34746ms;
  animation-delay: 24610ms;
}

@keyframes move-frames-57 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }

  to {
    transform: translate3d(6vw, -113vh, 0);
  }
}

.circle-container2:nth-child(57) .circle {
  animation-delay: 903ms;
}

.circle-container2:nth-child(58) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-58;
  animation-duration: 31745ms;
  animation-delay: 23189ms;
}

@keyframes move-frames-58 {
  from {
    transform: translate3d(49vw, 106vh, 0);
  }

  to {
    transform: translate3d(57vw, -128vh, 0);
  }
}

.circle-container2:nth-child(58) .circle {
  animation-delay: 3601ms;
}

.circle-container2:nth-child(59) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-59;
  animation-duration: 30816ms;
  animation-delay: 31745ms;
}

@keyframes move-frames-59 {
  from {
    transform: translate3d(59vw, 105vh, 0);
  }

  to {
    transform: translate3d(61vw, -123vh, 0);
  }
}

.circle-container2:nth-child(59) .circle {
  animation-delay: 1368ms;
}

.circle-container2:nth-child(60) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-60;
  animation-duration: 33647ms;
  animation-delay: 5961ms;
}

@keyframes move-frames-60 {
  from {
    transform: translate3d(88vw, 106vh, 0);
  }

  to {
    transform: translate3d(18vw, -126vh, 0);
  }
}

.circle-container2:nth-child(60) .circle {
  animation-delay: 679ms;
}

.circle-container2:nth-child(61) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-61;
  animation-duration: 31448ms;
  animation-delay: 29691ms;
}

@keyframes move-frames-61 {
  from {
    transform: translate3d(82vw, 106vh, 0);
  }

  to {
    transform: translate3d(15vw, -118vh, 0);
  }
}

.circle-container2:nth-child(61) .circle {
  animation-delay: 1833ms;
}

.circle-container2:nth-child(62) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-62;
  animation-duration: 28308ms;
  animation-delay: 19263ms;
}

@keyframes move-frames-62 {
  from {
    transform: translate3d(55vw, 104vh, 0);
  }

  to {
    transform: translate3d(86vw, -128vh, 0);
  }
}

.circle-container2:nth-child(62) .circle {
  animation-delay: 3453ms;
}

.circle-container2:nth-child(63) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-63;
  animation-duration: 30972ms;
  animation-delay: 3844ms;
}

@keyframes move-frames-63 {
  from {
    transform: translate3d(49vw, 101vh, 0);
  }

  to {
    transform: translate3d(87vw, -107vh, 0);
  }
}

.circle-container2:nth-child(63) .circle {
  animation-delay: 626ms;
}

.circle-container2:nth-child(64) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-64;
  animation-duration: 33170ms;
  animation-delay: 144ms;
}

@keyframes move-frames-64 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }

  to {
    transform: translate3d(34vw, -111vh, 0);
  }
}

.circle-container2:nth-child(64) .circle {
  animation-delay: 1099ms;
}

.circle-container2:nth-child(65) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-65;
  animation-duration: 36461ms;
  animation-delay: 851ms;
}

@keyframes move-frames-65 {
  from {
    transform: translate3d(25vw, 103vh, 0);
  }

  to {
    transform: translate3d(79vw, -131vh, 0);
  }
}

.circle-container2:nth-child(65) .circle {
  animation-delay: 2827ms;
}

.circle-container2:nth-child(66) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-66;
  animation-duration: 32334ms;
  animation-delay: 21586ms;
}

@keyframes move-frames-66 {
  from {
    transform: translate3d(52vw, 107vh, 0);
  }

  to {
    transform: translate3d(44vw, -133vh, 0);
  }
}

.circle-container2:nth-child(66) .circle {
  animation-delay: 3780ms;
}

.circle-container2:nth-child(67) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-67;
  animation-duration: 34301ms;
  animation-delay: 34104ms;
}

@keyframes move-frames-67 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }

  to {
    transform: translate3d(29vw, -113vh, 0);
  }
}

.circle-container2:nth-child(67) .circle {
  animation-delay: 26ms;
}

.circle-container2:nth-child(68) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-68;
  animation-duration: 34599ms;
  animation-delay: 36245ms;
}

@keyframes move-frames-68 {
  from {
    transform: translate3d(74vw, 101vh, 0);
  }

  to {
    transform: translate3d(86vw, -103vh, 0);
  }
}

.circle-container2:nth-child(68) .circle {
  animation-delay: 2759ms;
}

.circle-container2:nth-child(69) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-69;
  animation-duration: 36460ms;
  animation-delay: 9061ms;
}

@keyframes move-frames-69 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }

  to {
    transform: translate3d(80vw, -122vh, 0);
  }
}

.circle-container2:nth-child(69) .circle {
  animation-delay: 2452ms;
}

.circle-container2:nth-child(70) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-70;
  animation-duration: 33436ms;
  animation-delay: 36789ms;
}

@keyframes move-frames-70 {
  from {
    transform: translate3d(74vw, 108vh, 0);
  }

  to {
    transform: translate3d(37vw, -124vh, 0);
  }
}

.circle-container2:nth-child(70) .circle {
  animation-delay: 3893ms;
}

.circle-container2:nth-child(71) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-71;
  animation-duration: 29878ms;
  animation-delay: 9226ms;
}

@keyframes move-frames-71 {
  from {
    transform: translate3d(62vw, 106vh, 0);
  }

  to {
    transform: translate3d(69vw, -135vh, 0);
  }
}

.circle-container2:nth-child(71) .circle {
  animation-delay: 2395ms;
}

.circle-container2:nth-child(72) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-72;
  animation-duration: 36655ms;
  animation-delay: 33632ms;
}

@keyframes move-frames-72 {
  from {
    transform: translate3d(41vw, 103vh, 0);
  }

  to {
    transform: translate3d(3vw, -117vh, 0);
  }
}

.circle-container2:nth-child(72) .circle {
  animation-delay: 2080ms;
}

.circle-container2:nth-child(73) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-73;
  animation-duration: 34472ms;
  animation-delay: 26092ms;
}

@keyframes move-frames-73 {
  from {
    transform: translate3d(12vw, 109vh, 0);
  }

  to {
    transform: translate3d(24vw, -134vh, 0);
  }
}

.circle-container2:nth-child(73) .circle {
  animation-delay: 313ms;
}

.circle-container2:nth-child(74) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-74;
  animation-duration: 31693ms;
  animation-delay: 27190ms;
}

@keyframes move-frames-74 {
  from {
    transform: translate3d(28vw, 102vh, 0);
  }

  to {
    transform: translate3d(71vw, -120vh, 0);
  }
}

.circle-container2:nth-child(74) .circle {
  animation-delay: 938ms;
}

.circle-container2:nth-child(75) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-75;
  animation-duration: 34065ms;
  animation-delay: 18116ms;
}

@keyframes move-frames-75 {
  from {
    transform: translate3d(42vw, 104vh, 0);
  }

  to {
    transform: translate3d(99vw, -107vh, 0);
  }
}

.circle-container2:nth-child(75) .circle {
  animation-delay: 588ms;
}

.circle-container2:nth-child(76) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-76;
  animation-duration: 30502ms;
  animation-delay: 26354ms;
}

@keyframes move-frames-76 {
  from {
    transform: translate3d(36vw, 104vh, 0);
  }

  to {
    transform: translate3d(89vw, -112vh, 0);
  }
}

.circle-container2:nth-child(76) .circle {
  animation-delay: 2460ms;
}

.circle-container2:nth-child(77) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-77;
  animation-duration: 31963ms;
  animation-delay: 23345ms;
}

@keyframes move-frames-77 {
  from {
    transform: translate3d(63vw, 105vh, 0);
  }

  to {
    transform: translate3d(94vw, -115vh, 0);
  }
}

.circle-container2:nth-child(77) .circle {
  animation-delay: 1193ms;
}

.circle-container2:nth-child(78) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-78;
  animation-duration: 30431ms;
  animation-delay: 22141ms;
}

@keyframes move-frames-78 {
  from {
    transform: translate3d(75vw, 109vh, 0);
  }

  to {
    transform: translate3d(16vw, -134vh, 0);
  }
}

.circle-container2:nth-child(78) .circle {
  animation-delay: 3693ms;
}

.circle-container2:nth-child(79) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-79;
  animation-duration: 36905ms;
  animation-delay: 20196ms;
}

@keyframes move-frames-79 {
  from {
    transform: translate3d(45vw, 102vh, 0);
  }

  to {
    transform: translate3d(67vw, -104vh, 0);
  }
}

.circle-container2:nth-child(79) .circle {
  animation-delay: 3138ms;
}

.circle-container2:nth-child(80) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-80;
  animation-duration: 33414ms;
  animation-delay: 28543ms;
}

@keyframes move-frames-80 {
  from {
    transform: translate3d(76vw, 109vh, 0);
  }

  to {
    transform: translate3d(90vw, -126vh, 0);
  }
}

.circle-container2:nth-child(80) .circle {
  animation-delay: 2014ms;
}

.circle-container2:nth-child(81) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-81;
  animation-duration: 34416ms;
  animation-delay: 26911ms;
}

@keyframes move-frames-81 {
  from {
    transform: translate3d(7vw, 103vh, 0);
  }

  to {
    transform: translate3d(67vw, -115vh, 0);
  }
}

.circle-container2:nth-child(81) .circle {
  animation-delay: 3870ms;
}

.circle-container2:nth-child(82) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-82;
  animation-duration: 29375ms;
  animation-delay: 36449ms;
}

@keyframes move-frames-82 {
  from {
    transform: translate3d(50vw, 109vh, 0);
  }

  to {
    transform: translate3d(87vw, -127vh, 0);
  }
}

.circle-container2:nth-child(82) .circle {
  animation-delay: 1207ms;
}

.circle-container2:nth-child(83) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-83;
  animation-duration: 34734ms;
  animation-delay: 2453ms;
}

@keyframes move-frames-83 {
  from {
    transform: translate3d(75vw, 108vh, 0);
  }

  to {
    transform: translate3d(23vw, -125vh, 0);
  }
}

.circle-container2:nth-child(83) .circle {
  animation-delay: 3365ms;
}

.circle-container2:nth-child(84) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-84;
  animation-duration: 32584ms;
  animation-delay: 22435ms;
}

@keyframes move-frames-84 {
  from {
    transform: translate3d(52vw, 106vh, 0);
  }

  to {
    transform: translate3d(17vw, -111vh, 0);
  }
}

.circle-container2:nth-child(84) .circle {
  animation-delay: 2065ms;
}

.circle-container2:nth-child(85) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-85;
  animation-duration: 30914ms;
  animation-delay: 4700ms;
}

@keyframes move-frames-85 {
  from {
    transform: translate3d(31vw, 103vh, 0);
  }

  to {
    transform: translate3d(29vw, -121vh, 0);
  }
}

.circle-container2:nth-child(85) .circle {
  animation-delay: 471ms;
}

.circle-container2:nth-child(86) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-86;
  animation-duration: 34295ms;
  animation-delay: 36675ms;
}

@keyframes move-frames-86 {
  from {
    transform: translate3d(78vw, 101vh, 0);
  }

  to {
    transform: translate3d(35vw, -117vh, 0);
  }
}

.circle-container2:nth-child(86) .circle {
  animation-delay: 2669ms;
}

.circle-container2:nth-child(87) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-87;
  animation-duration: 31904ms;
  animation-delay: 7428ms;
}

@keyframes move-frames-87 {
  from {
    transform: translate3d(30vw, 109vh, 0);
  }

  to {
    transform: translate3d(70vw, -132vh, 0);
  }
}

.circle-container2:nth-child(87) .circle {
  animation-delay: 2520ms;
}

.circle-container2:nth-child(88) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-88;
  animation-duration: 32334ms;
  animation-delay: 17801ms;
}

@keyframes move-frames-88 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }

  to {
    transform: translate3d(61vw, -107vh, 0);
  }
}

.circle-container2:nth-child(88) .circle {
  animation-delay: 2175ms;
}

.circle-container2:nth-child(89) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-89;
  animation-duration: 34126ms;
  animation-delay: 30329ms;
}

@keyframes move-frames-89 {
  from {
    transform: translate3d(71vw, 103vh, 0);
  }

  to {
    transform: translate3d(44vw, -125vh, 0);
  }
}

.circle-container2:nth-child(89) .circle {
  animation-delay: 979ms;
}

.circle-container2:nth-child(90) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-90;
  animation-duration: 30221ms;
  animation-delay: 27967ms;
}

@keyframes move-frames-90 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }

  to {
    transform: translate3d(43vw, -131vh, 0);
  }
}

.circle-container2:nth-child(90) .circle {
  animation-delay: 3340ms;
}

.circle-container2:nth-child(91) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-91;
  animation-duration: 33856ms;
  animation-delay: 22877ms;
}

@keyframes move-frames-91 {
  from {
    transform: translate3d(38vw, 101vh, 0);
  }

  to {
    transform: translate3d(50vw, -109vh, 0);
  }
}

.circle-container2:nth-child(91) .circle {
  animation-delay: 2601ms;
}

.circle-container2:nth-child(92) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-92;
  animation-duration: 33790ms;
  animation-delay: 25047ms;
}

@keyframes move-frames-92 {
  from {
    transform: translate3d(28vw, 105vh, 0);
  }

  to {
    transform: translate3d(67vw, -109vh, 0);
  }
}

.circle-container2:nth-child(92) .circle {
  animation-delay: 995ms;
}

.circle-container2:nth-child(93) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-93;
  animation-duration: 32289ms;
  animation-delay: 8081ms;
}

@keyframes move-frames-93 {
  from {
    transform: translate3d(7vw, 109vh, 0);
  }

  to {
    transform: translate3d(25vw, -129vh, 0);
  }
}

.circle-container2:nth-child(93) .circle {
  animation-delay: 1297ms;
}

.circle-container2:nth-child(94) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-94;
  animation-duration: 36464ms;
  animation-delay: 35818ms;
}

@keyframes move-frames-94 {
  from {
    transform: translate3d(98vw, 102vh, 0);
  }

  to {
    transform: translate3d(73vw, -104vh, 0);
  }
}

.circle-container2:nth-child(94) .circle {
  animation-delay: 1388ms;
}

.circle-container2:nth-child(95) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-95;
  animation-duration: 35765ms;
  animation-delay: 21102ms;
}

@keyframes move-frames-95 {
  from {
    transform: translate3d(39vw, 104vh, 0);
  }

  to {
    transform: translate3d(36vw, -111vh, 0);
  }
}

.circle-container2:nth-child(95) .circle {
  animation-delay: 2921ms;
}

.circle-container2:nth-child(96) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-96;
  animation-duration: 30502ms;
  animation-delay: 27214ms;
}

@keyframes move-frames-96 {
  from {
    transform: translate3d(15vw, 108vh, 0);
  }

  to {
    transform: translate3d(13vw, -136vh, 0);
  }
}

.circle-container2:nth-child(96) .circle {
  animation-delay: 3485ms;
}

.circle-container2:nth-child(97) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-97;
  animation-duration: 35275ms;
  animation-delay: 12588ms;
}

@keyframes move-frames-97 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }

  to {
    transform: translate3d(11vw, -122vh, 0);
  }
}

.circle-container2:nth-child(97) .circle {
  animation-delay: 2892ms;
}

.circle-container2:nth-child(98) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-98;
  animation-duration: 35993ms;
  animation-delay: 27568ms;
}

@keyframes move-frames-98 {
  from {
    transform: translate3d(18vw, 101vh, 0);
  }

  to {
    transform: translate3d(33vw, -119vh, 0);
  }
}

.circle-container2:nth-child(98) .circle {
  animation-delay: 74ms;
}

.circle-container2:nth-child(99) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-99;
  animation-duration: 28213ms;
  animation-delay: 33567ms;
}

@keyframes move-frames-99 {
  from {
    transform: translate3d(95vw, 106vh, 0);
  }

  to {
    transform: translate3d(79vw, -135vh, 0);
  }
}

.circle-container2:nth-child(99) .circle {
  animation-delay: 198ms;
}

.circle-container2:nth-child(100) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-100;
  animation-duration: 29405ms;
  animation-delay: 30490ms;
}

@keyframes move-frames-100 {
  from {
    transform: translate3d(5vw, 103vh, 0);
  }

  to {
    transform: translate3d(5vw, -110vh, 0);
  }
}

.circle-container2:nth-child(100) .circle {
  animation-delay: 3981ms;
}

.circle-container2:nth-child(101) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-101;
  animation-duration: 29968ms;
  animation-delay: 9125ms;
}

@keyframes move-frames-101 {
  from {
    transform: translate3d(85vw, 107vh, 0);
  }

  to {
    transform: translate3d(71vw, -110vh, 0);
  }
}

.circle-container2:nth-child(101) .circle {
  animation-delay: 2201ms;
}

.circle-container2:nth-child(102) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-102;
  animation-duration: 28916ms;
  animation-delay: 22698ms;
}

@keyframes move-frames-102 {
  from {
    transform: translate3d(37vw, 110vh, 0);
  }

  to {
    transform: translate3d(20vw, -120vh, 0);
  }
}

.circle-container2:nth-child(102) .circle {
  animation-delay: 3872ms;
}

.circle-container2:nth-child(103) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-103;
  animation-duration: 29503ms;
  animation-delay: 21791ms;
}

@keyframes move-frames-103 {
  from {
    transform: translate3d(86vw, 104vh, 0);
  }

  to {
    transform: translate3d(2vw, -134vh, 0);
  }
}

.circle-container2:nth-child(103) .circle {
  animation-delay: 3570ms;
}

.circle-container2:nth-child(104) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-104;
  animation-duration: 30734ms;
  animation-delay: 30465ms;
}

@keyframes move-frames-104 {
  from {
    transform: translate3d(98vw, 101vh, 0);
  }

  to {
    transform: translate3d(35vw, -109vh, 0);
  }
}

.circle-container2:nth-child(104) .circle {
  animation-delay: 3690ms;
}

.circle-container2:nth-child(105) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-105;
  animation-duration: 30204ms;
  animation-delay: 14424ms;
}

@keyframes move-frames-105 {
  from {
    transform: translate3d(25vw, 109vh, 0);
  }

  to {
    transform: translate3d(71vw, -126vh, 0);
  }
}

.circle-container2:nth-child(105) .circle {
  animation-delay: 3170ms;
}

.circle-container2:nth-child(106) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-106;
  animation-duration: 32148ms;
  animation-delay: 19165ms;
}

@keyframes move-frames-106 {
  from {
    transform: translate3d(84vw, 105vh, 0);
  }

  to {
    transform: translate3d(52vw, -128vh, 0);
  }
}

.circle-container2:nth-child(106) .circle {
  animation-delay: 3842ms;
}

.circle-container2:nth-child(107) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-107;
  animation-duration: 36943ms;
  animation-delay: 12405ms;
}

@keyframes move-frames-107 {
  from {
    transform: translate3d(16vw, 110vh, 0);
  }

  to {
    transform: translate3d(1vw, -112vh, 0);
  }
}

.circle-container2:nth-child(107) .circle {
  animation-delay: 3906ms;
}

.circle-container2:nth-child(108) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-108;
  animation-duration: 35467ms;
  animation-delay: 32037ms;
}

@keyframes move-frames-108 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }

  to {
    transform: translate3d(93vw, -118vh, 0);
  }
}

.circle-container2:nth-child(108) .circle {
  animation-delay: 1682ms;
}

.circle-container2:nth-child(109) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-109;
  animation-duration: 32289ms;
  animation-delay: 25940ms;
}

@keyframes move-frames-109 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }

  to {
    transform: translate3d(78vw, -133vh, 0);
  }
}

.circle-container2:nth-child(109) .circle {
  animation-delay: 859ms;
}

.circle-container2:nth-child(110) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-110;
  animation-duration: 33249ms;
  animation-delay: 11206ms;
}

@keyframes move-frames-110 {
  from {
    transform: translate3d(78vw, 107vh, 0);
  }

  to {
    transform: translate3d(42vw, -128vh, 0);
  }
}

.circle-container2:nth-child(110) .circle {
  animation-delay: 1543ms;
}

.circle-container2:nth-child(111) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-111;
  animation-duration: 32235ms;
  animation-delay: 22721ms;
}

@keyframes move-frames-111 {
  from {
    transform: translate3d(93vw, 107vh, 0);
  }

  to {
    transform: translate3d(72vw, -129vh, 0);
  }
}

.circle-container2:nth-child(111) .circle {
  animation-delay: 926ms;
}

.circle-container2:nth-child(112) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-112;
  animation-duration: 36584ms;
  animation-delay: 17442ms;
}

@keyframes move-frames-112 {
  from {
    transform: translate3d(66vw, 103vh, 0);
  }

  to {
    transform: translate3d(84vw, -128vh, 0);
  }
}

.circle-container2:nth-child(112) .circle {
  animation-delay: 3359ms;
}

.circle-container2:nth-child(113) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-113;
  animation-duration: 31260ms;
  animation-delay: 8370ms;
}

@keyframes move-frames-113 {
  from {
    transform: translate3d(46vw, 108vh, 0);
  }

  to {
    transform: translate3d(42vw, -132vh, 0);
  }
}

.circle-container2:nth-child(113) .circle {
  animation-delay: 1133ms;
}

.circle-container2:nth-child(114) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-114;
  animation-duration: 29389ms;
  animation-delay: 5792ms;
}

@keyframes move-frames-114 {
  from {
    transform: translate3d(3vw, 106vh, 0);
  }

  to {
    transform: translate3d(58vw, -126vh, 0);
  }
}

.circle-container2:nth-child(114) .circle {
  animation-delay: 3600ms;
}

.circle-container2:nth-child(115) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-115;
  animation-duration: 28611ms;
  animation-delay: 21873ms;
}

@keyframes move-frames-115 {
  from {
    transform: translate3d(26vw, 107vh, 0);
  }

  to {
    transform: translate3d(20vw, -123vh, 0);
  }
}

.circle-container2:nth-child(115) .circle {
  animation-delay: 1609ms;
}

.circle-container2:nth-child(116) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-116;
  animation-duration: 35599ms;
  animation-delay: 734ms;
}

@keyframes move-frames-116 {
  from {
    transform: translate3d(86vw, 107vh, 0);
  }

  to {
    transform: translate3d(41vw, -133vh, 0);
  }
}

.circle-container2:nth-child(116) .circle {
  animation-delay: 1710ms;
}

.circle-container2:nth-child(117) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-117;
  animation-duration: 35085ms;
  animation-delay: 32674ms;
}

@keyframes move-frames-117 {
  from {
    transform: translate3d(65vw, 107vh, 0);
  }

  to {
    transform: translate3d(86vw, -111vh, 0);
  }
}

.circle-container2:nth-child(117) .circle {
  animation-delay: 391ms;
}

.circle-container2:nth-child(118) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-118;
  animation-duration: 28426ms;
  animation-delay: 13214ms;
}

@keyframes move-frames-118 {
  from {
    transform: translate3d(88vw, 109vh, 0);
  }

  to {
    transform: translate3d(39vw, -131vh, 0);
  }
}

.circle-container2:nth-child(118) .circle {
  animation-delay: 2889ms;
}

.circle-container2:nth-child(119) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-119;
  animation-duration: 31179ms;
  animation-delay: 1360ms;
}

@keyframes move-frames-119 {
  from {
    transform: translate3d(17vw, 109vh, 0);
  }

  to {
    transform: translate3d(86vw, -118vh, 0);
  }
}

.circle-container2:nth-child(119) .circle {
  animation-delay: 1297ms;
}

.circle-container2:nth-child(120) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-120;
  animation-duration: 35514ms;
  animation-delay: 23725ms;
}

@keyframes move-frames-120 {
  from {
    transform: translate3d(70vw, 106vh, 0);
  }

  to {
    transform: translate3d(37vw, -122vh, 0);
  }
}

.circle-container2:nth-child(120) .circle {
  animation-delay: 1030ms;
}

.circle-container2:nth-child(121) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-121;
  animation-duration: 32154ms;
  animation-delay: 30916ms;
}

@keyframes move-frames-121 {
  from {
    transform: translate3d(69vw, 109vh, 0);
  }

  to {
    transform: translate3d(90vw, -117vh, 0);
  }
}

.circle-container2:nth-child(121) .circle {
  animation-delay: 2214ms;
}

.circle-container2:nth-child(122) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-122;
  animation-duration: 35331ms;
  animation-delay: 32463ms;
}

@keyframes move-frames-122 {
  from {
    transform: translate3d(47vw, 108vh, 0);
  }

  to {
    transform: translate3d(51vw, -125vh, 0);
  }
}

.circle-container2:nth-child(122) .circle {
  animation-delay: 3707ms;
}

.circle-container2:nth-child(123) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-123;
  animation-duration: 28684ms;
  animation-delay: 36500ms;
}

@keyframes move-frames-123 {
  from {
    transform: translate3d(44vw, 109vh, 0);
  }

  to {
    transform: translate3d(31vw, -122vh, 0);
  }
}

.circle-container2:nth-child(123) .circle {
  animation-delay: 447ms;
}

.circle-container2:nth-child(124) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-124;
  animation-duration: 36090ms;
  animation-delay: 7067ms;
}

@keyframes move-frames-124 {
  from {
    transform: translate3d(74vw, 107vh, 0);
  }

  to {
    transform: translate3d(89vw, -111vh, 0);
  }
}

.circle-container2:nth-child(124) .circle {
  animation-delay: 2240ms;
}

.circle-container2:nth-child(125) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-125;
  animation-duration: 28814ms;
  animation-delay: 9308ms;
}

@keyframes move-frames-125 {
  from {
    transform: translate3d(69vw, 108vh, 0);
  }

  to {
    transform: translate3d(78vw, -122vh, 0);
  }
}

.circle-container2:nth-child(125) .circle {
  animation-delay: 69ms;
}

.circle-container2:nth-child(126) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-126;
  animation-duration: 29080ms;
  animation-delay: 28145ms;
}

@keyframes move-frames-126 {
  from {
    transform: translate3d(99vw, 105vh, 0);
  }

  to {
    transform: translate3d(42vw, -112vh, 0);
  }
}

.circle-container2:nth-child(126) .circle {
  animation-delay: 7ms;
}

.circle-container2:nth-child(127) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-127;
  animation-duration: 28660ms;
  animation-delay: 33412ms;
}

@keyframes move-frames-127 {
  from {
    transform: translate3d(69vw, 102vh, 0);
  }

  to {
    transform: translate3d(23vw, -111vh, 0);
  }
}

.circle-container2:nth-child(127) .circle {
  animation-delay: 2882ms;
}

.circle-container2:nth-child(128) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-128;
  animation-duration: 31174ms;
  animation-delay: 24214ms;
}

@keyframes move-frames-128 {
  from {
    transform: translate3d(7vw, 106vh, 0);
  }

  to {
    transform: translate3d(23vw, -115vh, 0);
  }
}

.circle-container2:nth-child(128) .circle {
  animation-delay: 3082ms;
}

.circle-container2:nth-child(129) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-129;
  animation-duration: 32171ms;
  animation-delay: 34435ms;
}

@keyframes move-frames-129 {
  from {
    transform: translate3d(31vw, 101vh, 0);
  }

  to {
    transform: translate3d(34vw, -129vh, 0);
  }
}

.circle-container2:nth-child(129) .circle {
  animation-delay: 872ms;
}

.circle-container2:nth-child(130) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-130;
  animation-duration: 36636ms;
  animation-delay: 5949ms;
}

@keyframes move-frames-130 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }

  to {
    transform: translate3d(5vw, -130vh, 0);
  }
}

.circle-container2:nth-child(130) .circle {
  animation-delay: 2219ms;
}

.circle-container2:nth-child(131) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-131;
  animation-duration: 31452ms;
  animation-delay: 9541ms;
}

@keyframes move-frames-131 {
  from {
    transform: translate3d(71vw, 107vh, 0);
  }

  to {
    transform: translate3d(25vw, -126vh, 0);
  }
}

.circle-container2:nth-child(131) .circle {
  animation-delay: 421ms;
}

.circle-container2:nth-child(132) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-132;
  animation-duration: 29409ms;
  animation-delay: 5131ms;
}

@keyframes move-frames-132 {
  from {
    transform: translate3d(96vw, 110vh, 0);
  }

  to {
    transform: translate3d(16vw, -119vh, 0);
  }
}

.circle-container2:nth-child(132) .circle {
  animation-delay: 2650ms;
}

.circle-container2:nth-child(133) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-133;
  animation-duration: 34135ms;
  animation-delay: 22884ms;
}

@keyframes move-frames-133 {
  from {
    transform: translate3d(38vw, 103vh, 0);
  }

  to {
    transform: translate3d(9vw, -122vh, 0);
  }
}

.circle-container2:nth-child(133) .circle {
  animation-delay: 2856ms;
}

.circle-container2:nth-child(134) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-134;
  animation-duration: 28153ms;
  animation-delay: 13261ms;
}

@keyframes move-frames-134 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }

  to {
    transform: translate3d(10vw, -133vh, 0);
  }
}

.circle-container2:nth-child(134) .circle {
  animation-delay: 3709ms;
}

.circle-container2:nth-child(135) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-135;
  animation-duration: 32127ms;
  animation-delay: 34353ms;
}

@keyframes move-frames-135 {
  from {
    transform: translate3d(5vw, 110vh, 0);
  }

  to {
    transform: translate3d(97vw, -121vh, 0);
  }
}

.circle-container2:nth-child(135) .circle {
  animation-delay: 799ms;
}

.circle-container2:nth-child(136) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-136;
  animation-duration: 29737ms;
  animation-delay: 25429ms;
}

@keyframes move-frames-136 {
  from {
    transform: translate3d(61vw, 108vh, 0);
  }

  to {
    transform: translate3d(97vw, -131vh, 0);
  }
}

.circle-container2:nth-child(136) .circle {
  animation-delay: 2671ms;
}

.circle-container2:nth-child(137) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-137;
  animation-duration: 34817ms;
  animation-delay: 16326ms;
}

@keyframes move-frames-137 {
  from {
    transform: translate3d(13vw, 109vh, 0);
  }

  to {
    transform: translate3d(63vw, -132vh, 0);
  }
}

.circle-container2:nth-child(137) .circle {
  animation-delay: 1927ms;
}

.circle-container2:nth-child(138) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-138;
  animation-duration: 36324ms;
  animation-delay: 3871ms;
}

@keyframes move-frames-138 {
  from {
    transform: translate3d(3vw, 109vh, 0);
  }

  to {
    transform: translate3d(5vw, -119vh, 0);
  }
}

.circle-container2:nth-child(138) .circle {
  animation-delay: 2589ms;
}

.circle-container2:nth-child(139) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-139;
  animation-duration: 33537ms;
  animation-delay: 11929ms;
}

@keyframes move-frames-139 {
  from {
    transform: translate3d(59vw, 109vh, 0);
  }

  to {
    transform: translate3d(9vw, -133vh, 0);
  }
}

.circle-container2:nth-child(139) .circle {
  animation-delay: 424ms;
}

.circle-container2:nth-child(140) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-140;
  animation-duration: 34662ms;
  animation-delay: 21605ms;
}

@keyframes move-frames-140 {
  from {
    transform: translate3d(84vw, 106vh, 0);
  }

  to {
    transform: translate3d(15vw, -136vh, 0);
  }
}

.circle-container2:nth-child(140) .circle {
  animation-delay: 3430ms;
}

.circle-container2:nth-child(141) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-141;
  animation-duration: 31241ms;
  animation-delay: 15328ms;
}

@keyframes move-frames-141 {
  from {
    transform: translate3d(30vw, 101vh, 0);
  }

  to {
    transform: translate3d(42vw, -107vh, 0);
  }
}

.circle-container2:nth-child(141) .circle {
  animation-delay: 632ms;
}

.circle-container2:nth-child(142) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-142;
  animation-duration: 28931ms;
  animation-delay: 10856ms;
}

@keyframes move-frames-142 {
  from {
    transform: translate3d(66vw, 105vh, 0);
  }

  to {
    transform: translate3d(26vw, -128vh, 0);
  }
}

.circle-container2:nth-child(142) .circle {
  animation-delay: 507ms;
}

.circle-container2:nth-child(143) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-143;
  animation-duration: 35135ms;
  animation-delay: 5486ms;
}

@keyframes move-frames-143 {
  from {
    transform: translate3d(80vw, 105vh, 0);
  }

  to {
    transform: translate3d(28vw, -128vh, 0);
  }
}

.circle-container2:nth-child(143) .circle {
  animation-delay: 1790ms;
}

.circle-container2:nth-child(144) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-144;
  animation-duration: 34630ms;
  animation-delay: 19793ms;
}

@keyframes move-frames-144 {
  from {
    transform: translate3d(68vw, 108vh, 0);
  }

  to {
    transform: translate3d(66vw, -130vh, 0);
  }
}

.circle-container2:nth-child(144) .circle {
  animation-delay: 1756ms;
}

.circle-container2:nth-child(145) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-145;
  animation-duration: 29462ms;
  animation-delay: 25517ms;
}

@keyframes move-frames-145 {
  from {
    transform: translate3d(49vw, 104vh, 0);
  }

  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}

.circle-container2:nth-child(145) .circle {
  animation-delay: 3803ms;
}

.circle-container2:nth-child(146) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-146;
  animation-duration: 35256ms;
  animation-delay: 9570ms;
}

@keyframes move-frames-146 {
  from {
    transform: translate3d(15vw, 106vh, 0);
  }

  to {
    transform: translate3d(29vw, -117vh, 0);
  }
}

.circle-container2:nth-child(146) .circle {
  animation-delay: 3970ms;
}

.circle-container2:nth-child(147) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-147;
  animation-duration: 33627ms;
  animation-delay: 36109ms;
}

@keyframes move-frames-147 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }

  to {
    transform: translate3d(67vw, -123vh, 0);
  }
}

.circle-container2:nth-child(147) .circle {
  animation-delay: 2714ms;
}

.circle-container2:nth-child(148) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-148;
  animation-duration: 34801ms;
  animation-delay: 743ms;
}

@keyframes move-frames-148 {
  from {
    transform: translate3d(82vw, 102vh, 0);
  }

  to {
    transform: translate3d(43vw, -115vh, 0);
  }
}

.circle-container2:nth-child(148) .circle {
  animation-delay: 2064ms;
}

.circle-container2:nth-child(149) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-149;
  animation-duration: 33669ms;
  animation-delay: 32275ms;
}

@keyframes move-frames-149 {
  from {
    transform: translate3d(45vw, 101vh, 0);
  }

  to {
    transform: translate3d(23vw, -103vh, 0);
  }
}

.circle-container2:nth-child(149) .circle {
  animation-delay: 1628ms;
}

.circle-container2:nth-child(150) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-150;
  animation-duration: 34795ms;
  animation-delay: 4749ms;
}

@keyframes move-frames-150 {
  from {
    transform: translate3d(14vw, 110vh, 0);
  }

  to {
    transform: translate3d(73vw, -123vh, 0);
  }
}

.circle-container2:nth-child(150) .circle {
  animation-delay: 2055ms;
}

.circle-container2:nth-child(151) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-151;
  animation-duration: 33885ms;
  animation-delay: 29883ms;
}

@keyframes move-frames-151 {
  from {
    transform: translate3d(4vw, 103vh, 0);
  }

  to {
    transform: translate3d(51vw, -110vh, 0);
  }
}

.circle-container2:nth-child(151) .circle {
  animation-delay: 2272ms;
}

.circle-container2:nth-child(152) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-152;
  animation-duration: 36908ms;
  animation-delay: 36920ms;
}

@keyframes move-frames-152 {
  from {
    transform: translate3d(30vw, 102vh, 0);
  }

  to {
    transform: translate3d(99vw, -124vh, 0);
  }
}

.circle-container2:nth-child(152) .circle {
  animation-delay: 778ms;
}

.circle-container2:nth-child(153) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-153;
  animation-duration: 28352ms;
  animation-delay: 7520ms;
}

@keyframes move-frames-153 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }

  to {
    transform: translate3d(93vw, -111vh, 0);
  }
}

.circle-container2:nth-child(153) .circle {
  animation-delay: 720ms;
}

.circle-container2:nth-child(154) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-154;
  animation-duration: 36188ms;
  animation-delay: 2989ms;
}

@keyframes move-frames-154 {
  from {
    transform: translate3d(81vw, 102vh, 0);
  }

  to {
    transform: translate3d(20vw, -126vh, 0);
  }
}

.circle-container2:nth-child(154) .circle {
  animation-delay: 527ms;
}

.circle-container2:nth-child(155) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-155;
  animation-duration: 32707ms;
  animation-delay: 19860ms;
}

@keyframes move-frames-155 {
  from {
    transform: translate3d(99vw, 108vh, 0);
  }

  to {
    transform: translate3d(28vw, -133vh, 0);
  }
}

.circle-container2:nth-child(155) .circle {
  animation-delay: 3559ms;
}

.circle-container2:nth-child(156) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-156;
  animation-duration: 36634ms;
  animation-delay: 5748ms;
}

@keyframes move-frames-156 {
  from {
    transform: translate3d(16vw, 109vh, 0);
  }

  to {
    transform: translate3d(75vw, -138vh, 0);
  }
}

.circle-container2:nth-child(156) .circle {
  animation-delay: 3847ms;
}

.circle-container2:nth-child(157) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-157;
  animation-duration: 34146ms;
  animation-delay: 1383ms;
}

@keyframes move-frames-157 {
  from {
    transform: translate3d(14vw, 107vh, 0);
  }

  to {
    transform: translate3d(5vw, -109vh, 0);
  }
}

.circle-container2:nth-child(157) .circle {
  animation-delay: 3120ms;
}

.circle-container2:nth-child(158) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-158;
  animation-duration: 34661ms;
  animation-delay: 24156ms;
}

@keyframes move-frames-158 {
  from {
    transform: translate3d(13vw, 106vh, 0);
  }

  to {
    transform: translate3d(80vw, -108vh, 0);
  }
}

.circle-container2:nth-child(158) .circle {
  animation-delay: 3187ms;
}

.circle-container2:nth-child(159) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-159;
  animation-duration: 31555ms;
  animation-delay: 35581ms;
}

@keyframes move-frames-159 {
  from {
    transform: translate3d(4vw, 108vh, 0);
  }

  to {
    transform: translate3d(4vw, -124vh, 0);
  }
}

.circle-container2:nth-child(159) .circle {
  animation-delay: 2664ms;
}

.circle-container2:nth-child(160) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-160;
  animation-duration: 34063ms;
  animation-delay: 36698ms;
}

@keyframes move-frames-160 {
  from {
    transform: translate3d(19vw, 104vh, 0);
  }

  to {
    transform: translate3d(10vw, -125vh, 0);
  }
}

.circle-container2:nth-child(160) .circle {
  animation-delay: 3848ms;
}

.circle-container2:nth-child(161) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-161;
  animation-duration: 33296ms;
  animation-delay: 31319ms;
}

@keyframes move-frames-161 {
  from {
    transform: translate3d(9vw, 107vh, 0);
  }

  to {
    transform: translate3d(78vw, -120vh, 0);
  }
}

.circle-container2:nth-child(161) .circle {
  animation-delay: 2432ms;
}

.circle-container2:nth-child(162) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-162;
  animation-duration: 35812ms;
  animation-delay: 14297ms;
}

@keyframes move-frames-162 {
  from {
    transform: translate3d(74vw, 105vh, 0);
  }

  to {
    transform: translate3d(25vw, -121vh, 0);
  }
}

.circle-container2:nth-child(162) .circle {
  animation-delay: 3380ms;
}

.circle-container2:nth-child(163) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-163;
  animation-duration: 34820ms;
  animation-delay: 26445ms;
}

@keyframes move-frames-163 {
  from {
    transform: translate3d(10vw, 101vh, 0);
  }

  to {
    transform: translate3d(95vw, -118vh, 0);
  }
}

.circle-container2:nth-child(163) .circle {
  animation-delay: 1034ms;
}

.circle-container2:nth-child(164) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-164;
  animation-duration: 29794ms;
  animation-delay: 16383ms;
}

@keyframes move-frames-164 {
  from {
    transform: translate3d(27vw, 101vh, 0);
  }

  to {
    transform: translate3d(43vw, -116vh, 0);
  }
}

.circle-container2:nth-child(164) .circle {
  animation-delay: 927ms;
}

.circle-container2:nth-child(165) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-165;
  animation-duration: 34792ms;
  animation-delay: 30428ms;
}

@keyframes move-frames-165 {
  from {
    transform: translate3d(77vw, 107vh, 0);
  }

  to {
    transform: translate3d(15vw, -121vh, 0);
  }
}

.circle-container2:nth-child(165) .circle {
  animation-delay: 2331ms;
}

.circle-container2:nth-child(166) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-166;
  animation-duration: 31779ms;
  animation-delay: 8039ms;
}

@keyframes move-frames-166 {
  from {
    transform: translate3d(55vw, 109vh, 0);
  }

  to {
    transform: translate3d(36vw, -133vh, 0);
  }
}

.circle-container2:nth-child(166) .circle {
  animation-delay: 1501ms;
}

.circle-container2:nth-child(167) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-167;
  animation-duration: 33558ms;
  animation-delay: 18201ms;
}

@keyframes move-frames-167 {
  from {
    transform: translate3d(80vw, 110vh, 0);
  }

  to {
    transform: translate3d(35vw, -113vh, 0);
  }
}

.circle-container2:nth-child(167) .circle {
  animation-delay: 3387ms;
}

.circle-container2:nth-child(168) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-168;
  animation-duration: 33625ms;
  animation-delay: 21179ms;
}

@keyframes move-frames-168 {
  from {
    transform: translate3d(32vw, 108vh, 0);
  }

  to {
    transform: translate3d(2vw, -130vh, 0);
  }
}

.circle-container2:nth-child(168) .circle {
  animation-delay: 1929ms;
}

.circle-container2:nth-child(169) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-169;
  animation-duration: 35307ms;
  animation-delay: 29480ms;
}

@keyframes move-frames-169 {
  from {
    transform: translate3d(31vw, 102vh, 0);
  }

  to {
    transform: translate3d(61vw, -130vh, 0);
  }
}

.circle-container2:nth-child(169) .circle {
  animation-delay: 1027ms;
}

.circle-container2:nth-child(170) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-170;
  animation-duration: 31199ms;
  animation-delay: 28197ms;
}

@keyframes move-frames-170 {
  from {
    transform: translate3d(75vw, 105vh, 0);
  }

  to {
    transform: translate3d(16vw, -127vh, 0);
  }
}

.circle-container2:nth-child(170) .circle {
  animation-delay: 3401ms;
}

.circle-container2:nth-child(171) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-171;
  animation-duration: 32710ms;
  animation-delay: 25985ms;
}

@keyframes move-frames-171 {
  from {
    transform: translate3d(40vw, 108vh, 0);
  }

  to {
    transform: translate3d(16vw, -129vh, 0);
  }
}

.circle-container2:nth-child(171) .circle {
  animation-delay: 3100ms;
}

.circle-container2:nth-child(172) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-172;
  animation-duration: 36094ms;
  animation-delay: 22192ms;
}

@keyframes move-frames-172 {
  from {
    transform: translate3d(70vw, 107vh, 0);
  }

  to {
    transform: translate3d(33vw, -133vh, 0);
  }
}

.circle-container2:nth-child(172) .circle {
  animation-delay: 3260ms;
}

.circle-container2:nth-child(173) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-173;
  animation-duration: 28325ms;
  animation-delay: 14848ms;
}

@keyframes move-frames-173 {
  from {
    transform: translate3d(39vw, 107vh, 0);
  }

  to {
    transform: translate3d(55vw, -137vh, 0);
  }
}

.circle-container2:nth-child(173) .circle {
  animation-delay: 1544ms;
}

.circle-container2:nth-child(174) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-174;
  animation-duration: 31498ms;
  animation-delay: 34860ms;
}

@keyframes move-frames-174 {
  from {
    transform: translate3d(82vw, 105vh, 0);
  }

  to {
    transform: translate3d(68vw, -110vh, 0);
  }
}

.circle-container2:nth-child(174) .circle {
  animation-delay: 1419ms;
}

.circle-container2:nth-child(175) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-175;
  animation-duration: 32296ms;
  animation-delay: 12310ms;
}

@keyframes move-frames-175 {
  from {
    transform: translate3d(48vw, 105vh, 0);
  }

  to {
    transform: translate3d(72vw, -123vh, 0);
  }
}

.circle-container2:nth-child(175) .circle {
  animation-delay: 89ms;
}

.circle-container2:nth-child(176) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-176;
  animation-duration: 31695ms;
  animation-delay: 24667ms;
}

@keyframes move-frames-176 {
  from {
    transform: translate3d(22vw, 109vh, 0);
  }

  to {
    transform: translate3d(4vw, -126vh, 0);
  }
}

.circle-container2:nth-child(176) .circle {
  animation-delay: 3187ms;
}

.circle-container2:nth-child(177) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-177;
  animation-duration: 31664ms;
  animation-delay: 18211ms;
}

@keyframes move-frames-177 {
  from {
    transform: translate3d(49vw, 101vh, 0);
  }

  to {
    transform: translate3d(29vw, -125vh, 0);
  }
}

.circle-container2:nth-child(177) .circle {
  animation-delay: 2819ms;
}

.circle-container2:nth-child(178) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-178;
  animation-duration: 28392ms;
  animation-delay: 34567ms;
}

@keyframes move-frames-178 {
  from {
    transform: translate3d(51vw, 110vh, 0);
  }

  to {
    transform: translate3d(100vw, -131vh, 0);
  }
}

.circle-container2:nth-child(178) .circle {
  animation-delay: 2722ms;
}

.circle-container2:nth-child(179) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-179;
  animation-duration: 30963ms;
  animation-delay: 24078ms;
}

@keyframes move-frames-179 {
  from {
    transform: translate3d(52vw, 108vh, 0);
  }

  to {
    transform: translate3d(41vw, -136vh, 0);
  }
}

.circle-container2:nth-child(179) .circle {
  animation-delay: 1139ms;
}

.circle-container2:nth-child(180) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-180;
  animation-duration: 33541ms;
  animation-delay: 17296ms;
}

@keyframes move-frames-180 {
  from {
    transform: translate3d(57vw, 102vh, 0);
  }

  to {
    transform: translate3d(58vw, -132vh, 0);
  }
}

.circle-container2:nth-child(180) .circle {
  animation-delay: 2123ms;
}

.circle-container2:nth-child(181) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-181;
  animation-duration: 32098ms;
  animation-delay: 24109ms;
}

@keyframes move-frames-181 {
  from {
    transform: translate3d(16vw, 107vh, 0);
  }

  to {
    transform: translate3d(71vw, -116vh, 0);
  }
}

.circle-container2:nth-child(181) .circle {
  animation-delay: 215ms;
}

.circle-container2:nth-child(182) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-182;
  animation-duration: 33521ms;
  animation-delay: 20413ms;
}

@keyframes move-frames-182 {
  from {
    transform: translate3d(1vw, 106vh, 0);
  }

  to {
    transform: translate3d(17vw, -136vh, 0);
  }
}

.circle-container2:nth-child(182) .circle {
  animation-delay: 2956ms;
}

.circle-container2:nth-child(183) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-183;
  animation-duration: 35438ms;
  animation-delay: 29444ms;
}

@keyframes move-frames-183 {
  from {
    transform: translate3d(20vw, 102vh, 0);
  }

  to {
    transform: translate3d(35vw, -126vh, 0);
  }
}

.circle-container2:nth-child(183) .circle {
  animation-delay: 3321ms;
}

.circle-container2:nth-child(184) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-184;
  animation-duration: 34660ms;
  animation-delay: 22744ms;
}

@keyframes move-frames-184 {
  from {
    transform: translate3d(12vw, 103vh, 0);
  }

  to {
    transform: translate3d(10vw, -121vh, 0);
  }
}

.circle-container2:nth-child(184) .circle {
  animation-delay: 3626ms;
}

.circle-container2:nth-child(185) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-185;
  animation-duration: 33549ms;
  animation-delay: 17214ms;
}

@keyframes move-frames-185 {
  from {
    transform: translate3d(3vw, 109vh, 0);
  }

  to {
    transform: translate3d(97vw, -111vh, 0);
  }
}

.circle-container2:nth-child(185) .circle {
  animation-delay: 3664ms;
}

.circle-container2:nth-child(186) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-186;
  animation-duration: 30535ms;
  animation-delay: 32724ms;
}

@keyframes move-frames-186 {
  from {
    transform: translate3d(78vw, 103vh, 0);
  }

  to {
    transform: translate3d(75vw, -115vh, 0);
  }
}

.circle-container2:nth-child(186) .circle {
  animation-delay: 3761ms;
}

.circle-container2:nth-child(187) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-187;
  animation-duration: 35438ms;
  animation-delay: 18335ms;
}

@keyframes move-frames-187 {
  from {
    transform: translate3d(45vw, 104vh, 0);
  }

  to {
    transform: translate3d(99vw, -120vh, 0);
  }
}

.circle-container2:nth-child(187) .circle {
  animation-delay: 477ms;
}

.circle-container2:nth-child(188) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-188;
  animation-duration: 33244ms;
  animation-delay: 6241ms;
}

@keyframes move-frames-188 {
  from {
    transform: translate3d(17vw, 107vh, 0);
  }

  to {
    transform: translate3d(22vw, -126vh, 0);
  }
}

.circle-container2:nth-child(188) .circle {
  animation-delay: 899ms;
}

.circle-container2:nth-child(189) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-189;
  animation-duration: 34146ms;
  animation-delay: 22185ms;
}

@keyframes move-frames-189 {
  from {
    transform: translate3d(96vw, 105vh, 0);
  }

  to {
    transform: translate3d(54vw, -129vh, 0);
  }
}

.circle-container2:nth-child(189) .circle {
  animation-delay: 1636ms;
}

.circle-container2:nth-child(190) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-190;
  animation-duration: 31554ms;
  animation-delay: 3633ms;
}

@keyframes move-frames-190 {
  from {
    transform: translate3d(58vw, 105vh, 0);
  }

  to {
    transform: translate3d(31vw, -133vh, 0);
  }
}

.circle-container2:nth-child(190) .circle {
  animation-delay: 1078ms;
}

.circle-container2:nth-child(191) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-191;
  animation-duration: 28930ms;
  animation-delay: 26467ms;
}

@keyframes move-frames-191 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }

  to {
    transform: translate3d(62vw, -108vh, 0);
  }
}

.circle-container2:nth-child(191) .circle {
  animation-delay: 3548ms;
}

.circle-container2:nth-child(192) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-192;
  animation-duration: 28483ms;
  animation-delay: 34660ms;
}

@keyframes move-frames-192 {
  from {
    transform: translate3d(85vw, 103vh, 0);
  }

  to {
    transform: translate3d(54vw, -121vh, 0);
  }
}

.circle-container2:nth-child(192) .circle {
  animation-delay: 440ms;
}

.circle-container2:nth-child(193) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-193;
  animation-duration: 32543ms;
  animation-delay: 10492ms;
}

@keyframes move-frames-193 {
  from {
    transform: translate3d(59vw, 104vh, 0);
  }

  to {
    transform: translate3d(69vw, -131vh, 0);
  }
}

.circle-container2:nth-child(193) .circle {
  animation-delay: 829ms;
}

.circle-container2:nth-child(194) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-194;
  animation-duration: 30645ms;
  animation-delay: 12380ms;
}

@keyframes move-frames-194 {
  from {
    transform: translate3d(12vw, 110vh, 0);
  }

  to {
    transform: translate3d(45vw, -127vh, 0);
  }
}

.circle-container2:nth-child(194) .circle {
  animation-delay: 1113ms;
}

.circle-container2:nth-child(195) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-195;
  animation-duration: 34270ms;
  animation-delay: 15876ms;
}

@keyframes move-frames-195 {
  from {
    transform: translate3d(50vw, 101vh, 0);
  }

  to {
    transform: translate3d(87vw, -118vh, 0);
  }
}

.circle-container2:nth-child(195) .circle {
  animation-delay: 1624ms;
}

.circle-container2:nth-child(196) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-196;
  animation-duration: 29392ms;
  animation-delay: 25816ms;
}

@keyframes move-frames-196 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }

  to {
    transform: translate3d(63vw, -107vh, 0);
  }
}

.circle-container2:nth-child(196) .circle {
  animation-delay: 1477ms;
}

.circle-container2:nth-child(197) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-197;
  animation-duration: 36800ms;
  animation-delay: 5377ms;
}

@keyframes move-frames-197 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }

  to {
    transform: translate3d(82vw, -130vh, 0);
  }
}

.circle-container2:nth-child(197) .circle {
  animation-delay: 3276ms;
}

.circle-container2:nth-child(198) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-198;
  animation-duration: 28760ms;
  animation-delay: 21968ms;
}

@keyframes move-frames-198 {
  from {
    transform: translate3d(16vw, 106vh, 0);
  }

  to {
    transform: translate3d(5vw, -126vh, 0);
  }
}

.circle-container2:nth-child(198) .circle {
  animation-delay: 2084ms;
}

.circle-container2:nth-child(199) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-199;
  animation-duration: 33994ms;
  animation-delay: 7886ms;
}

@keyframes move-frames-199 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }

  to {
    transform: translate3d(25vw, -123vh, 0);
  }
}

.circle-container2:nth-child(199) .circle {
  animation-delay: 367ms;
}

.circle-container2:nth-child(200) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-200;
  animation-duration: 32701ms;
  animation-delay: 18293ms;
}

@keyframes move-frames-200 {
  from {
    transform: translate3d(61vw, 103vh, 0);
  }

  to {
    transform: translate3d(24vw, -110vh, 0);
  }
}

.circle-container2:nth-child(200) .circle {
  animation-delay: 3411ms;
}

.bold {
  font-weight: 300;
}

.pageT div:hover {
  color: rgb(252, 238, 164);
}

.highLightP {
  color: rgb(0, 162, 255);
}

.line2 {
  height: 3px;
  width: 100%;
  background-color: #b8b8b8c4;
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #000000;
  z-index: 100000;
  position: fixed;
}

.loadingText2:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

.freemintLoading {
  font-size: 25px;
  margin-top: 3%;
  background: -webkit-linear-gradient(#1df739, #b3fe00, #b3fe00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic;
  font-weight: 300;
}

.maxAmount {
  color: white;
}

.freemintfade {
  display: none;
}

.page-loader .loadLogo {

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  top: 30%;
  position: relative;

  font-size: 40px;
  margin-top: 2%;
}

.loadLogo img {
  width: 250px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  animation: zoominoutsinglefeatured 3s infinite;
}

@media screen and (max-width: 499px) {
  .refH {
    text-align: center;
    font-size: 18px;
  }

  .video-container {
    width: 90%;
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    display: flex;

  }

  .video-container iframe {
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 {
    width: 90%;
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 iframe {
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .back {
    width: 9%;
    margin-left: 20px;
    position: sticky;
    bottom: 30px;
    transition: transform .2s;
    cursor: pointer;
  }

  .formMain-3 {
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    overflow: scroll;

    display: flex;
    flex-flow: column nowrap;
    animation: popupAnimation 0.5s ease forwards;
    border-radius: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    z-index: 1;
  }

  .btn2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
  }

  .register {
    background-color: #5669d3;
    color: rgb(0, 0, 0);
    padding: 5px;
     color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 130px;
    text-align: center;
  }
  .connectBtn {
    background-color: #21181d;
    color: rgb(0, 0, 0);
    padding:5px;
 
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 130px;
  }

  .register:hover {
    transform: scale(1.1);
  }

  .register2 {
    border: 2px solid #5669d3;
    width: 100px;
    text-align: center;
    padding: 5px;
    border-radius: 100px;
    color: rgb(0, 0, 0);
    background-color: #18181a00;
    transition: transform .2s;
    cursor: pointer;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    margin-top: 20px;
  }


  .conH {
    color: #ffffff;
    font-size: 30px;
    letter-spacing: 3px;
    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;

  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  .placeAndWL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: fit-content;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 4%;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: 300;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;


  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: 300;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: #ffffff;
    font-size: 90px;
    font-family: 'Fortuner', sans-serif;
    color: white;
    line-height: 1.3;
    margin-top: 6%;
    color: #5669d3;
    font-weight: 300;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 20px;
    text-align: center;
    font-size: 18px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .mintDiv {

    width: 95%;
    /* From https://css.glass */
    background: rgb(29, 31, 34);
    background: linear-gradient(180deg, rgba(29, 31, 34, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(7, 16, 25, 1) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgb(228, 228, 228);
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    margin-bottom: 5%;
    margin-top: 5%;

  }

  .errorMessage {

    font-size: 15px;
    color: #FF4742;
    text-align: center;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);


  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 10px;
    padding-left: 10px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {

    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #5669d3;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;

    color: #4b2e02;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border-radius: 6px;

    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
    color: white;
    background-color: rgba(255, 255, 255, 0);
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;

    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;

  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }

  input{
    color: white;
    width: 250px;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
    background-color: #000000;
    border: 1px solid white;
  }

  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;

    border: none;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;

  }

  .btnfos-0-4 {
    font-size: 27px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
    border: none;
    background-color: rgb(228, 228, 228);

    box-shadow: #c27400 0px 5px;
    border-radius: 20px;
    justify-content: center;
    margin-top: 5%;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    color: white;

  }


  .btnfos-0-3:hover {

    color: rgb(228, 228, 228);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #5669d3;
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 95%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: white 3px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }

  .pageT {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .pageT div {
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    font-size: 20px;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 18px;
    margin-top: 20px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    margin-top: 20px;
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 27px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    box-shadow: rgba(252, 224, 65, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    color: white;
    background: #FF4742;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;

    z-index: 100000000000;


    margin-bottom: 10%;

  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {

    display: none;
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: rgb(26, 30, 32);
    background: linear-gradient(180deg, rgba(26, 30, 32, 1) 0%, rgba(20, 21, 22, 1) 50%, rgba(19, 20, 24, 1) 100%);
    min-height: 100vh;
    height: max-content;

  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    border-radius: 50px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  @keyframes border-glow {
    0% {
      box-shadow: 0 0 0 0 rgba(240, 160, 13, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;

    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .refH {
    text-align: center;
    font-size: 18px;
  }

  .video-container {
    width: 90%;
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    display: flex;

  }

  .video-container iframe {
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 {
    width: 90%;
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 iframe {
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .back {
    width: 9%;
    margin-left: 20px;
    position: sticky;
    bottom: 30px;
    transition: transform .2s;
    cursor: pointer;
  }

  .formMain-3 {
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    overflow: scroll;

    display: flex;
    flex-flow: column nowrap;
    animation: popupAnimation 0.5s ease forwards;
    border-radius: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 20px;
    z-index: 1;
  }

  .btn2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
  }

  .register {
    background-color: #5669d3;
    color: rgb(0, 0, 0);
    padding: 8px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 160px;
    text-align: center;
  }
  .connectBtn {
    background-color: #21181d;
    color: rgb(0, 0, 0);
    padding: 8px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 160px;
  }

  .register:hover {
    transform: scale(1.1);
  }

  .register2 {
    border: 2px solid #5669d3;
    width: 100px;
    text-align: center;
    padding: 5px;
    border-radius: 100px;
    color: rgb(0, 0, 0);
    background-color: #18181a00;
    transition: transform .2s;
    cursor: pointer;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    margin-top: 20px;
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .placeAndWL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: fit-content;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 4%;
    margin-left: auto;
    margin-right: auto;
    display: none;

  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;

  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: 300;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;


  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: 300;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: #ffffff;
    font-size: 100px;
    margin-top: 6%;
    color: white;
    line-height: 1.2;
    margin-left: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
    font-family: 'Fortuner', sans-serif;
    color: #5669d3;
    font-weight: 300;
  }

  .conH {
    color: #ffffff;
    font-size: 40px;
    letter-spacing: 3px;
    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 20px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
  }

  .mintDiv {

    width: 90%;
    /* From https://css.glass */
    background: rgb(29, 31, 34);
    background: linear-gradient(180deg, rgba(29, 31, 34, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(7, 16, 25, 1) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgb(228, 228, 228);
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    margin-bottom: 5%;
    margin-top: 5%;

  }

  .errorMessage {

    font-size: 16px;
    color: #FF4742;
    text-align: center;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);


  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;

  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 10px;
    padding-left: 10px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    color: #6d3891b6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {

    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #5669d3;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
    flex-flow: column nowrap;

  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;

    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;

    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  input{
    color: white;
    width: 250px;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
    background-color: #000000;
    border: 1px solid white;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;

    border: none;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;

  }

  .btnfos-0-4 {
    font-size: 27px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
    border: none;
    background-color: rgb(228, 228, 228);

    box-shadow: #c27400 0px 5px;
    border-radius: 20px;
    justify-content: center;
    margin-top: 5%;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    color: white;

  }


  .btnfos-0-3:hover {

    color: rgb(228, 228, 228);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #5669d3;
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 95%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: white 3px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 22px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }

  .pageT {
    font-size: 22px;
    color: white;
    margin-bottom: 2%;
    display: flex;
  }

  .pageT div {
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 27px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    box-shadow: rgba(252, 224, 65, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    color: white;
    background: #FF4742;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;

    z-index: 100000000000;


    margin-bottom: 10%;

  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {

    display: none;
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: rgb(26, 30, 32);
    background: linear-gradient(180deg, rgba(26, 30, 32, 1) 0%, rgba(20, 21, 22, 1) 50%, rgba(19, 20, 24, 1) 100%);
    min-height: 100vh;
    height: max-content;

  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 50px;
    margin-top: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  @keyframes border-glow {
    0% {
      box-shadow: 0 0 0 0 rgba(240, 160, 13, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes border-glow {
    0% {
      box-shadow: 0 0 0 0 rgba(240, 160, 13, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;

    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .refH {
    text-align: center;
    font-size: 20px;
  }

  .video-container {
    width: 90%;
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    display: flex;

  }

  .video-container iframe {
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 {
    width: 90%;
    height: 50vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 iframe {
    width: 100%;
    height: 50vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .back {
    width: 3.5%;
    margin-left: 20px;
    position: sticky;
    bottom: 50px;
    transition: transform .2s;
    cursor: pointer;
  }

  .formMain-3 {
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    overflow: scroll;

    display: flex;
    flex-flow: column nowrap;
    animation: popupAnimation 0.5s ease forwards;
    border-radius: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 50px;
    z-index: 1;
  }

  .btn2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
  }

  .register {
    background-color: #5669d3;
    color: rgb(0, 0, 0);
    padding: 8px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 160px;
    text-align: center;
  }
  .connectBtn {
    background-color: #21181d;
    color: rgb(0, 0, 0);
    padding: 8px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 160px;
  }

  .register:hover {
    transform: scale(1.1);
  }

  .register2 {
    border: 2px solid #5669d3;
    width: 150px;
    text-align: center;
    padding: 7px;
    border-radius: 100px;
    color: rgb(0, 0, 0);
    background-color: #18181a00;
    transition: transform .2s;
    cursor: pointer;
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  .placeAndWL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: fit-content;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 4%;
    margin-left: auto;
    margin-right: auto;
    display: none;

  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: 300;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;


  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: 300;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: #ffffff;
    font-size: 120px;
    margin-top: 6%;
    font-family: 'Fortuner', sans-serif;
    color: white;
    line-height: 1.3;
    color: #5669d3;
    font-weight: 300;
  }

  .conH {
    color: #ffffff;
    font-size: 50px;

    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 23px;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
  }

  .mintDiv {

    width: 60%;
    /* From https://css.glass */
    background: rgb(29, 31, 34);
    background: linear-gradient(180deg, rgba(29, 31, 34, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(7, 16, 25, 1) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgb(228, 228, 228);
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    margin-bottom: 5%;
    margin-top: 5%;

  }

  .errorMessage {

    font-size: 18px;
    color: #FF4742;
    text-align: center;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);


  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 10px;
    padding-left: 10px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #5669d3;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
    flex-flow: column nowrap;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;

    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;

    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }

  input{
    color: white;
    width: 250px;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
    background-color: #000000;
    border: 1px solid white;
  }

  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;

    border: none;

    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;

  }

  .btnfos-0-4 {
    font-size: 27px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
    border: none;
    background-color: rgb(228, 228, 228);

    box-shadow: #c27400 0px 5px;
    border-radius: 20px;
    justify-content: center;
    margin-top: 5%;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    color: white;
  }


  .btnfos-0-3:hover {

    color: rgb(228, 228, 228);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #5669d3;
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 95%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: white 3px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 22px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;

  }

  .pageT {
    font-size: 22px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-bottom: 2%;
  }

  .pageT div {
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0.568);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    margin-top: 20px;
  }

  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    box-shadow: rgba(252, 224, 65, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    color: white;
    background: #FF4742;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;

    z-index: 100000000000;


    margin-bottom: 10%;
  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {

    display: none;
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: rgb(26, 30, 32);
    background: linear-gradient(180deg, rgba(26, 30, 32, 1) 0%, rgba(20, 21, 22, 1) 50%, rgba(19, 20, 24, 1) 100%);
    min-height: 100vh;
    height: max-content;

  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 400px;


    border-radius: 50px;

    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;

  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  @keyframes border-glow {
    0% {
      box-shadow: 0 0 0 0 rgba(240, 160, 13, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;

    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .refH {
    text-align: center;
    font-size: 20px;
  }

  .video-container {
    width: 80%;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    display: flex;

  }

  .video-container iframe {
    width: 100%;
    height: 80vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 {
    width: 80%;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 iframe {
    width: 100%;
    height: 80vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .back {
    width: 3.5%;
    margin-left: 20px;
    position: sticky;
    bottom: 50px;
    transition: transform .2s;
    cursor: pointer;
  }

  .formMain-3 {
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    overflow: scroll;

    display: flex;
    flex-flow: column nowrap;
    animation: popupAnimation 0.5s ease forwards;
    border-radius: 20px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 50px;
    z-index: 1;
  }

  .btn2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
  }

  .register {
    background-color: #5669d3;
    color: rgb(0, 0, 0);
    padding: 8px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 150px;
    text-align: center;
  }
  .connectBtn {
    background-color: #21181d;
    color: rgb(0, 0, 0);
    padding: 8px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 150px;
  }
  .register:hover {
    transform: scale(1.1);
  }

  .register2 {
    border: 2px solid #5669d3;
    width: 150px;
    text-align: center;
    padding: 7px;
    border-radius: 100px;
    color: rgb(0, 0, 0);
    background-color: #18181a00;
    transition: transform .2s;
    cursor: pointer;
  }

  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }


  .placeAndWL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: fit-content;
    align-items: center;
    margin-top: 4%;
    width: 80%;
    display: none;

  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: 300;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;


  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: 300;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: #ffffff;
    font-size: 110px;
    font-family: 'Fortuner', sans-serif;
    color: white;
    line-height: 1.2;
    color: #5669d3;
    font-weight: 300;
  }

  .conH {
    color: #ffffff;
    font-size: 70px;

    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    font-weight: 300;
  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 20px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }


  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 18px;
    text-align: justify;
    font-size: 20px;
    font-size: 23px;
    text-align: left;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
  }

  .mintDiv {

    width: 45%;
    /* From https://css.glass */
    background: rgb(29, 31, 34);
    background: linear-gradient(180deg, rgba(29, 31, 34, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(7, 16, 25, 1) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgb(228, 228, 228);
    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .errorMessage {

    font-size: 18px;
    color: #FF4742;
    text-align: center;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 23px;

    text-align: center;
    color: rgb(255, 255, 255);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 10px;
    padding-left: 10px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #5669d3;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;

    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;

    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }

  input{
    color: white;
    width: 250px;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
    background-color: #000000;
    border: 1px solid white;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;

    border: none;

    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;

  }

  .btnfos-0-4 {
    font-size: 27px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
    border: none;
    background-color: rgb(228, 228, 228);

    box-shadow: #c27400 0px 5px;
    border-radius: 20px;
    justify-content: center;
    margin-top: 5%;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    color: black;
    color: white;
  }


  .btnfos-0-3:hover {

    color: rgb(228, 228, 228);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #5669d3;
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 95%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: white 3px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 12px;
  }

  .pageT {
    font-size: 22px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .pageT div {
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    margin-top: 10px;
  }

  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    box-shadow: rgba(252, 224, 65, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    color: white;
    background: #FF4742;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;

    z-index: 100000000000;



  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: rgb(26, 30, 32);
    background: linear-gradient(180deg, rgba(26, 30, 32, 1) 0%, rgba(20, 21, 22, 1) 50%, rgba(19, 20, 24, 1) 100%);
    min-height: 100vh;
    height: max-content;

  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 400px;
    border-radius: 50px;

  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  @keyframes border-glow {
    0% {
      box-shadow: 0 0 0 0 rgba(240, 160, 13, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;

    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .refH {
    text-align: center;
    font-size: 20px;
  }

  .video-container {
    width: 80%;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    display: flex;

  }

  .video-container iframe {
    width: 100%;
    height: 80vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 {
    width: 80%;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 iframe {
    width: 100%;
    height: 80vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .back {
    width: 3.5%;
    margin-left: 20px;
    position: sticky;
    bottom: 50px;
    transition: transform .2s;
    cursor: pointer;
  }

  .formMain-3 {
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    overflow: scroll;

    display: flex;
    flex-flow: column nowrap;
    animation: popupAnimation 0.5s ease forwards;
    border-radius: 20px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 50px;
    z-index: 1;
  }

  .btn2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .register {
    background-color: #5669d3;
    color: rgb(0, 0, 0);
    padding: 8px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 160px;
    text-align: center;
  }
  .connectBtn {
    background-color: #21181d;
    color: rgb(0, 0, 0);
    padding: 8px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 160px;
  }
  .register:hover {
    transform: scale(1.1);
  }

  .register2 {
    border: 2px solid #5669d3;
    width: 150px;
    text-align: center;
    padding: 7px;
    border-radius: 100px;
    color: rgb(0, 0, 0);
    background-color: #18181a00;
    transition: transform .2s;
    cursor: pointer;
    overflow: hidden;
    background-color: black;
    position: relative;
    overflow: hidden;
  }

  .register2 .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    color: black;
  }

  .register2:hover .initialText {
    opacity: 0;
    visibility: hidden;
    color: black;

  }


  .register2 .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    color: black;
  }



  .connectBtn .initialText {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .connectBtn:hover .initialText {
    opacity: 0;
    visibility: hidden;
  }

  .connectBtn .hoverText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  .connectBtn:hover .hoverText {
    opacity: 1;
    visibility: visible;
  }


  .cont {
    z-index: 10000000000;
  }

  .icons {
    display: flex;
  }

  .picAndChecked {
    display: flex;
    justify-content: center;
  }

  .picAndChecked img {
    width: 40px;
    height: 40px;
    margin-left: 5%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: 300;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }

  .price {
    text-align: center;
    font-size: 25px;
    color: #ffffff;

  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: 300;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: #ffffff;
    font-size: 120px;
    color: white;
    line-height: 1.2;
    color: #5669d3;
    font-weight: 300;
    font-family: 'Fortuner', sans-serif;

  }

  .conH {
    color: #ffffff;
    font-size: 70px;

    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    font-weight: 300;
  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 20px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 23px;
    text-align: left;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
  }

  .mintDiv {

    width: 40%;
    /* From https://css.glass */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 3px solid rgb(228, 228, 228);

    border-radius: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: rgb(29, 31, 34);
    background: linear-gradient(180deg, rgba(29, 31, 34, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(7, 16, 25, 1) 100%);
  }

  .errorMessage {

    font-size: 18px;
    color: #FF4742;
    text-align: center;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 23px;
    text-align: center;
    color: rgb(255, 255, 255);


  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;

    justify-content: space-evenly;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #5669d3;
    font-size: 90px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 6px;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;

  }

  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }


  .btnfos-0-3 {
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;

    justify-content: center;
    text-align: center;
    width: 250px;
    margin-bottom: 5%;
    border: none;
    border-radius: 6px;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: 5%;
    border-radius: 6px;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  input{
    color: white;
    width: 250px;
    font-size: 18px;
    padding: 5px;
    border-radius: 5px;
    background-color: #000000;
    border: 1px solid white;
  }

  .btnfos-0-4 {
    font-size: 27px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    border: none;
    background-color: rgb(228, 228, 228);

    box-shadow: #c27400 0px 5px;
    border-radius: 20px;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }

  .placeAndWL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: fit-content;
    align-items: center;
    margin-top: 4%;
    width: 80%;
    display: none;

  }

  .btnfos-0-3:hover {

    color: rgb(228, 228, 228);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #5669d3;
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 95%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
     margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: white 3px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 23px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 15px;

  }

  .pageT {
    font-size: 23px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .pageT div {
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    transition: 0.2 ease-in;

  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 20px;
    margin-top: 10px;

  }

  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    box-shadow: rgba(252, 224, 65, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    background: #FF4742;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    z-index: 100000000000;

  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 70%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 55px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: rgb(26, 30, 32);
    background: linear-gradient(180deg, rgba(26, 30, 32, 1) 0%, rgba(20, 21, 22, 1) 50%, rgba(19, 20, 24, 1) 100%);
    min-height: 100vh;
    height: max-content;

  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 500px;
    border-radius: 50px;

  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  @keyframes border-glow {
    0% {
      box-shadow: 0 0 0 0 rgba(240, 160, 13, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes border-glow {
    0% {
      box-shadow: 0 0 0 0 rgba(240, 160, 13, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;

    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 1920px) {
  .refH {
    text-align: center;
    font-size: 30px;
  }

  .video-container {
    width: 80%;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
    display: flex;

  }

  .video-container iframe {
    width: 100%;
    height: 80vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 {
    width: 80%;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .video-container2 iframe {
    width: 100%;
    height: 80vh;
    top: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }

  .back {
    width: 3.5%;
    margin-left: 20px;
    position: sticky;
    bottom: 50px;
    transition: transform .2s;
    cursor: pointer;
  }

  .formMain-3 {
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    position: fixed;
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    overflow: scroll;

    display: flex;
    flex-flow: column nowrap;
    animation: popupAnimation 0.5s ease forwards;
    border-radius: 30px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 70px;
    z-index: 1;
  }

  .btn2 {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
  }

  .register {
    background-color: #5669d3;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 200px;
    text-align: center;
  }
  .connectBtn {
    background-color: #21181d;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 22px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
    border: 2px solid white;
    border-radius: 100px;
    font-family: "JetBrains Mono", monospace;
    letter-spacing: 2px;
    border: 2px #ffffff solid;
    animation-name: glow-in-out;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    width: 200px;
  }

  .register:hover {
    transform: scale(1.1);
  }

  .register2 {
    border: 3px solid #5669d3;
    width: 200px;
    text-align: center;
    padding: 10px;
    border-radius: 100px;
    color: rgb(0, 0, 0);
    background-color: #18181a00;
    transition: transform .2s;
    cursor: pointer;
    font-size: 22px;

  }

  .button-85 {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: 25px;
    margin-top: 15px;

  }

  .conH {
    color: #ffffff;
    font-size: 100px;

    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 28px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 28px;
    font-weight: 300;
  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 30px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 28px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .placeAndWL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: fit-content;
    align-items: center;
    margin-top: 4%;
    width: 80%;
    display: none;

  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 30px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;
    font-weight: 300;
  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 55px;
    height: 55px;
    cursor: pointer;

  }

  .osPic2 {
    width: 90px;
    height: 90px;
    cursor: pointer;
    border-radius: 100px;
  }


  .price {
    text-align: center;
    font-size: 35px;
    color: #ffffff;


  }

  .discord {
    padding-right: 15px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    transform: scale(1.1);
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;
    font-weight: 300;
    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: #ffffff;
    font-size: 140px;
    font-family: 'Fortuner', sans-serif;
    color: white;
    line-height: 1.2;
    color: #5669d3;
    font-weight: 300;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.918);
    font-size: 33px;
    text-align: left;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
  }

  .mintDiv {

    width: 40%;
    /* From https://css.glass */
    background: rgb(29, 31, 34);
    background: linear-gradient(180deg, rgba(29, 31, 34, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(7, 16, 25, 1) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 4px solid rgb(228, 228, 228);
    border-radius: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .errorMessage {

    font-size: 30px;
    color: #FF4742;
    text-align: center;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(255, 255, 255);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord2 {
    padding-right: 20px;
    transition: transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord2:hover {
    transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: max-content;
  }

  .nftminter2 {
    color: #5669d3;
    font-size: 150px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;

    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 40px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
    width: 75px;
    border: none;
    cursor: pointer;
    border-radius: 6px;

    background-color: rgba(255, 255, 255, 0);
    border: 4px solid white;
  }

  .btnfos-0-2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 40px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
    width: 75px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border-radius: 6px;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;

    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 4px solid;
  }


  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }


  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }

  input{
    color: white;
    width: 330px;
    font-size: 24px;
    padding: 8px;
    border-radius: 7px;
    background-color: #000000;
    border: 2px solid white;
  }

  .btnfos-0-3 {
    font-size: 40px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 5px;

    border: none;
    justify-content: center;
    text-align: center;
    width: 330px;
    margin-bottom: 5%;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    border: white 4px solid;
    margin-top: 5%;
    border-radius: 6px;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;

  }

  .btnfos-0-4 {
    font-size: 37px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 15px;
    border: none;
    background-color: rgb(228, 228, 228);

    box-shadow: #c27400 0px 5px;
    border-radius: 20px;
    justify-content: center;
    text-align: center;
    width: fit-content;
    margin-bottom: 5%;
    color: black;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
  }


  .btnfos-0-3:hover {

    color: rgb(228, 228, 228);
    cursor: pointer;

  }

  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }

  .nftamount {
    color: #5669d3;
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: rgb(124, 199, 74);
    font-size: 25px;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #9e4c3c00;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: white 3px solid;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 35px;
    padding-left: 25px;
    padding-right: 25px;
    transition: transform .2s;
    border-radius: 50px;
    font-weight: 500;
    margin-left: 15px;
  }

  .pageT {
    font-size: 35px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .pageT div {
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Roboto', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 40px;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    box-shadow: rgba(252, 224, 65, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: transform .5s;
    font-weight: 500;
    color: white;
    background: #FF4742;
    border: 1px solid #FF4742;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;

    z-index: 100000000000;




  }

  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    width: 170px;
    height: 170px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: rgb(26, 30, 32);
    background: linear-gradient(180deg, rgba(26, 30, 32, 1) 0%, rgba(20, 21, 22, 1) 50%, rgba(19, 20, 24, 1) 100%);
    min-height: 100vh;
    height: max-content;

  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 700px;
    border-radius: 50px;

  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }


  @keyframes border-glow {
    0% {
      box-shadow: 0 0 0 0 rgba(240, 160, 13, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;

    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}